<template>
  <transition name="fade">
    <div class="list px-2">
      <div
        class="list__item"
        v-for="(item, index) in data"
        :key="index"
        @click="goTo(item.href)"
      >
        <img
          class="list__item__image"
          :src="require(`../assets/img/news/${item.img}`)"
          alt=""
        />
        <div class="list__item__content">
          <h3 class="list__item__content__title">{{ item.title }}</h3>
          <p class="list__item__content__details">
            {{ item.fonte }} - {{ item.date }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    goTo(link) {
      window.open(link, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
