<template>
  <section
    class="container page-section d-flex flex-column align-items-center"
    style=""
  >
    <news :data="notices_ce" />
    <b-button
      class="mt-5 px-5 text-uppercase"
      v-show="!showMore"
      variant="outline-success"
      @click="showMore = true"
      style=""
    >
      {{ $t("geral.buttons.ver-mais") }}
    </b-button>
    <list-news class="mt-5" v-show="showMore" :data="notices_ba" />
  </section>
</template>
<script>
import News from "../components/NewsGrid";
import ListNews from "../components/ListItems";
export default {
  components: {
    News,
    ListNews
  },
  data() {
    return {
      showMore: false,
      notices_ce: [
        {
          fonte: "IFCE",
          title:
            "IFCE lança portal para auxiliar comerciantes durante a quarentena",
          date: "31 de Março 2020",
          href:
            "https://ifce.edu.br/aracati/noticias/ifce-lanca-portal-para-auxiliar-comerciantes-durante-a-quarentena",
          img: "new1.jpeg"
        },
        {
          fonte: "Ministério Público do Trabalho",
          title:
            "Em tempos de isolamento social, MPT apoia aplicativo que aproxima micro e pequenos empreendedores de serviços essenciais dos consumidores",
          date: "29 de Junho de 2020",
          href:
            "https://ifce.edu.br/aracati/noticias/ifce-expande-portal-para-cadastrar-empresas-de-todo-o-pais",
          img: "new19.jpg"
        },
        {
          fonte: "Bahia.ba",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril 2020",
          href:
            "https://bahia.ba/covid19/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new3.jpg"
        },
        {
          fonte: "Secitece",
          title:
            "Secitece e IFCE unidos no auxílio a pequenos comerciantes durante a quarentena",
          date: "14 de Abril de 2020",
          href:
            "https://www.sct.ce.gov.br/2020/04/14/secitece-e-ifce-unidos-no-auxilio-a-pequenos-comerciantes-durante-a-quaretena/",
          img: "new1.jpeg"
        },
        {
          fonte: "Diário do Nordeste",
          title:
            "Plataforma do IFCE mapeia pequenos estabelecimentos em meio à pandemia",
          date: "15 de Abril de 2020",
          href:
            "https://diariodonordeste.verdesmares.com.br/editorias/negocios/online/plataforma-do-ifce-mapeia-pequenos-estabelecimentos-em-meio-a-pandemia-1.2234804",
          img: "new5.jpg"
        },
        {
          fonte: "Achou Gastronomia",
          title:
            "Plataforma criada pelo IFCE ganha destaque em todo o território Nacional",
          date: "16 de Abril de 2020",
          href:
            "https://achougastronomia.com.br/plataforma-criada-pelo-ifce-ganha-destaque-em-todo-o-territorio-nacional/",
          img: "new6.jpg"
        }
      ],
      notices_ba: [
        {
          fonte: "IFCE",
          title: "Ministério Público do Trabalho em Minas apoia o Fique no Lar",
          date: "30 de Junho de 2020",
          href:
            "https://ifce.edu.br/aracati/noticias/ministerio-publico-do-trabalho-em-minas-apoia-o-fique-no-lar",
          img: "new18.jpg"
        },
        {
          fonte: "IFCE",
          title: "IFCE expande sistema para cadastrar empresas de todo o país",
          date: "08 de Abril de 2020",
          href:
            "https://ifce.edu.br/aracati/noticias/ifce-expande-portal-para-cadastrar-empresas-de-todo-o-pais",
          img: "new7.jpg"
        },
        {
          fonte: "G1",
          title:
            "Plataforma cearense de apoio a pequenos e médios comerciantes na pandemia já atende a sete estados ",
          date: "15 de Abril de 2020",
          href:
            "https://g1.globo.com/ce/ceara/noticia/2020/04/15/plataforma-cearense-de-apoio-a-pequenos-e-medios-comerciantes-na-pandemia-ja-atende-a-sete-estados.ghtml",
          img: "new16.jpg"
        },
        {
          fonte: "Governo da Bahia",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia ",
          date: "13 de Abril de 2020",
          href:
            "http://www.ba.gov.br/noticias/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia",
          img: "new12.jpg"
        },
        {
          fonte: "G1",
          title:
            "Valdir Almeida traz os destaques do G1 desta quinta-feira (16)",
          date: "16 de Abril de 2020",
          href:
            "http://g1.globo.com/ceara/videos/t/todos-os-videos/v/valdir-almeida-traz-os-destaques-do-g1-desta-quinta-feira-16/8486430/",
          img: "new15.jpg"
        },
        {
          fonte: "Bahia Notícias",
          title:
            "Ferramenta 'Fique no Lar' já cadastrou delivery em 32 municípios baianos",
          date: "23 de Abril de 2020",
          href:
            "https://www.bahianoticias.com.br/noticia/247329-ferramenta-fique-no-lar-ja-cadastrou-delivery-em-32-municipios-baianos.html",
          img: "new17.jpg"
        },
        {
          fonte: "News BA",
          title:
            "Governo da Bahia mapeia delivery de micro e pequenas empresas",
          date: "13 de Abril de 2020",
          href:
            "https://newsba.com.br/2020/04/13/governo-da-bahia-mapeia-delivery-de-micro-e-pequenas-empresas/",
          img: "new3.jpg"
        },
        {
          fonte: "IFCE",
          title: "Secitece adere à plataforma Fique no Lar",
          date: "16 de Abril de 2020",
          href:
            "https://ifce.edu.br/noticias/noticias-de-destaque/secitece-adere-a-plataforma-fique-no-lar",
          img: "new1.jpeg"
        },
        {
          fonte: "IFCE",
          title: 'Plataforma "Fique no Lar" já alcança cinco estados',
          date: "14 de Abril de 2020",
          href:
            "https://ifce.edu.br/noticias/noticias-de-destaque/plataforma-fique-no-lar-ja-alcanca-comerciantes-de-quatro-estados",
          img: "new1.jpeg"
        },
        {
          fonte: "Portal do Servidor",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "http://www.portaldoservidor.ba.gov.br/noticias/2020-04-13/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia",
          img: "new11.jpg"
        },
        {
          fonte: "SECTI",
          title:
            "Covid-19: Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "http://www.secti.ba.gov.br/2020/04/1871/Covid-19-Governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-Bahia.html",
          img: "new3.jpg"
        },
        {
          fonte: "Casa Militar ",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "http://www.cmg.ba.gov.br/2020/04/153235,20/Governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-Bahia.html",
          img: "new3.jpg"
        },
        {
          fonte: "Tribuna do Ceará",
          title:
            "Site cearense já ajuda na venda de empreendedores de 4 estados",
          date: "14 de Abril de 2020",
          href: "https://www.instagram.com/p/B-93KwzgBM0/?igshid=15qcvq03zpxa2",
          img: "new13.jpg"
        },
        {
          fonte: "SAEB",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "http://www.saeb.ba.gov.br/2020/04/10057/Governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-Bahia.html",
          img: "new3.jpg"
        },
        {
          fonte: "SECOM",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "http://www.secom.ba.gov.br/2020/04/153235/Governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-Bahia.html",
          img: "new3.jpg"
        },
        {
          fonte: "SDE",
          title:
            'Aplicativo "Fique no lar" reúne micro e pequenas empresas que realizam delivery',
          date: "15 de Abril de 2020",
          href:
            "http://www.multclipp.com.br/verNoticia.aspx?c=0&n=39045264&e=1393",
          img: "new14.jpg"
        },
        {
          fonte: "TV Jaguar",
          title:
            "IFCE Aracati lança aplicativo para ajudar estabelecimentos comerciais a ofertar serviços durante a quarentena do corona vírus",
          date: "09 de Abril de 2020",
          href:
            "https://www.tvjaguar.com.br/noticia/11214/IFCE-Aracati-lan%C3%A7a-Aplicativo-para-ajudar-estabelecimentos-comerciais-a-ofertar-servi%C3%A7os-durante-quarentena-do-coronav%C3%ADrus..html",
          img: "new1.jpeg"
        },
        {
          fonte: "Prefeitura de Icapuí",
          title:
            "Prefeitura de Icapuí apoia ideia do IFCE proporcionando melhoria ao comércio local",
          date: "06 de Abril de 2020",
          href: "https://www.icapui.ce.gov.br/informa.php?id=938",
          img: "new5.jpg"
        },
        {
          fonte: "Fala Barreiras",
          title:
            "Covid-19: Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "https://www.falabarreiras.com/economia/covid-19-governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new3.jpg"
        },
        {
          fonte: "Acesse Política",
          title:
            "Covid-19: Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "https://www.acessepolitica.com.br/covid-19-governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new3.jpg"
        },
        {
          fonte: "Informa 1",
          title:
            "Covid-19: Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "https://informa1.com.br/covid-19-governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new3.jpg"
        },
        {
          fonte: "Lauro de Freitas",
          title:
            "Covid-19: Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "https://laurodefreitas.inf.br/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new8.jpg"
        },
        {
          fonte: "Jornal Grande Bahia",
          title:
            "Covid-19: Governo Rui Costa mapeia ‘delivery’ de micro e pequenas empresas na Bahia",
          date: "24 de Abril de 2020",
          href:
            "https://www.jornalgrandebahia.com.br/2020/04/covid-19-governo-rui-costa-mapeia-delivery-de-micro-e-pequenas-empresas-na-bahia/",
          img: "new9.jpg"
        },
        {
          fonte: "Acorda Cidade",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "https://www.acordacidade.com.br/noticias/225719/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia.html",
          img: "new3.jpg"
        },
        {
          fonte: "Bahia de Valor",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "https://www.bahiadevalor.com.br/2020/04/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new3.jpg"
        },
        {
          fonte: "Ilhéus Notícias",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "http://ilheusnoticias.com.br/2020/04/13/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new3.jpg"
        },
        {
          fonte: "Política Livre",
          title:
            "Covid-19: SDE mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "08 de Abril de 2020",
          href:
            "https://politicalivre.com.br/2020/04/covid-19-sde-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia/",
          img: "new10.jpg"
        },
        {
          fonte: "Rede News Oline",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "http://redenewsonline.com.br/portal/news/523/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia",
          img: "new3.jpg"
        },
        {
          fonte: "Bahiano News",
          title:
            "Governo mapeia delivery de micro e pequenas empresas em toda Bahia",
          date: "13 de Abril de 2020",
          href:
            "https://www.baianonews.com.br/noticia/6209/governo-mapeia-delivery-de-micro-e-pequenas-empresas-em-toda-bahia",
          img: "new3.jpg"
        },
        // LINK DO POVO NÃO FUNCIONA
        {
          fonte: "OPOVO",
          title:
            "Plataforma lançada pelo IFCE reúne comércios e serviços do Ceará e busca movimentar economias locais",
          date: "1 de Abril de 2020",
          href:
            "https://www.opovo.com.br/coronavirus/2020/04/01/plataforma-lancada-pelo-ifce-reune-comercios-e-servicos-do-ceara-e-busca-movimentar-economias-locais.html",
          img: "new1.jpeg"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.page-section {
  padding: 3rem 0;
}
</style>
