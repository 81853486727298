<template>
  <div class="news px-2">
    <div
      class="new"
      ref="new"
      :image="item.img"
      v-for="(item, index) in data"
      :key="index"
      @click="goTo(item.href)"
    >
      <div class="overlay">
        <p class="title">{{ limitCharacters(item.title, 50) }}</p>
        <p class="fonte">{{ item.fonte }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    limitCharacters(value, limit) {
      if (value.length > limit) return `${value.substr(0, limit)}...`;
      return value;
    },
    goTo(link) {
      window.open(link, "_blank");
    }
  },
  mounted() {
    this.$refs.new.forEach(item => {
      item.style.backgroundImage = `url(${require(`../assets/img/news/${item.attributes[0].nodeValue}`)})`;
    });
  }
};
</script>
